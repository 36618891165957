<template>
  <div
    v-if="level && level > 0"
    class="ui-label-author-rank"
  >
    <div class="rank-left" />
    <div class="rank-center">
      <div
        v-for="(num, index) in levelArr"
        :key="index"
        :class="`level-num-${num}`"
        class="rank-number"
      />
    </div>
    <div class="rank-right" />
  </div>
</template>
<script>
export default {
  props: {
    level: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    levelArr() {
      return String(this.level).split('');
    },
  },
};
</script>
<style lang="scss" src="./scss/author-rank.scss"></style>
