<template>
  <div
    v-if="iconUrl"
    :style="{backgroundImage: `url(${iconUrl})`}"
    class="ui-label-prestige-icon"
  />
</template>
<script>
export default {
  name: 'PrestigeIcon',
  props: {
    level: {
      type: [String, Number],
      default: '',
    },
    iconUrl: {
      type: [String],
      default: '',
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
    .ui-label-prestige-icon {
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        height: 15px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
</style>
