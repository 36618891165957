<template>
  <div
    v-if="showStatus"
    class="ui-label-online-status"
  >
    <div
      v-if="statusData.icon"
      class="ui-label-online-status__icon"
    >
      <img :src="statusData.icon">
    </div>
    <div
      v-else
      class="ui-label-online-status__icon icon-type-game"
    >
      <img :src="gameIcon">
    </div>
    <span
      :class="['ui-label-online-status__text', 'ui-nowrap', `text-status-${statusData.status}`]"
      :style="{ color: statusData.color }"
    >
      {{ statusData.text }}
    </span>
  </div>
</template>

<style lang="scss" src="./scss/online-status.scss"></style>

<script>
import imageOnlineStatus1 from './img/icon-status-online.png';

export default {
  props: {
    status: {
      type: [Number],
      default: -1,
    },
    gameCode: {
      type: [String],
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      onlineStatusMap: [
        {
          status: 1, // 在线
          icon: imageOnlineStatus1,
          text: '在线',
          color: '#13CAC5',
        },
        {
          status: 2, // 求组队
          icon: '',
          text: '找我玩',
          color: '#FCA100',
        },
        {
          status: 3, // 组队中
          icon: '',
          text: '组队中',
          color: '#FCA100',
        },
      ],
    };
  },
  computed: {
    isTeam() {
      return this.status === 2 || this.status === 3;
    },
    gameIcon() {
      return `https://static.gameplus.qq.com/business/gamepic/s_icon_${this.gameCode}.png`;
    },
    statusData() {
      let { status } = this;
      // 如果是组队状态，但是没有 gameCode，那么就兜底转换为在线状态
      if (this.isTeam && !this.gameCode) {
        status = 1;
      }
      return this.onlineStatusMap.find(item => item.status === status);
    },
    showStatus() {
      if (this.status <= 0 || this.status > 3) {
        return false;
      }
      return true;
    },
  },
};
</script>
